import i18n from "i18next"
import { initReactI18next } from "react-i18next"

const resources = {
  lt: {
    translation: {
      servicesTitle: "Mūsų teikiamos paslaugos",
      service: "autoveziu-paslaugos",
      service2: "kroviniu-pervezimas",
      service3: "muitines-proceduros",
      service4: "pirkimai-is-aukcionu",
      //header
      orderButton: "Užsisakyti",
      getButton: "Gaukite pasiūlymą",
      readMore: "Skaitykite daugiau",
      headerTitle: "Jūsų patikimas logistikos partneris",
      chooseLanguage: "Pasirinkite kalbą",
      headerDescription:
        "Užimame lyderiaujančias pozicijas naudotų transporto priemonių, transportavimo srityje iš Jungtinės Karalystės į Lietuvą, Latviją, Estiją, Olandiją ir Ukrainą.",
      transportInquiry: "Užsakymo užklausa",
      serviceReadMore: "/paslaugos/autoveziu-paslaugos/",
      serviceReadMore2: "/paslaugos/kroviniu-pervezimas/",
      serviceReadMore3: "/paslaugos/muitines-proceduros/",
      serviceReadMore4: "/paslaugos/pirkimai-is-aukcionu/",
      selectService: "Pasirinkite paslaugą",
      vehicleServices: "Autovežių paslaugos",
      cargoTransportation: "Krovinių pervežimas",
      customsProcedures: "Muitinės procedūros",
      auctionPurchases: "Pirkimai iš aukcionų",
      fromCountry: "Pasirinkite šalį iš kur",
      toCountry: "Pasirinkite šalį į kur",
      carMakeModel: "Automobilio markė, modelis",
      pickUpAddress: "Adresas iš kur paimti",
      dropOffAddress: "Adresas kur pristatyti",
      cargoType: "Pasirinkite krovinio tipą",
      weight: "Svoris (kg)",
      dimensions: "Išmatavimai (ilgis x plotis x aukštis)",
      loadingAddress: "Pakrovimo adresas",
      unloadingAddress: "Iškrovimo adresas",
      customsDetails: "Prašome nurodyti kokios muitinės procedūros reikia",
      auctionLink: "Įterpkite aukciono nuorodą",
      yourInformation: "Jūsų informacija",
      name: "Vardas",
      email: "El. paštas",
      phone: "Tel. nr.",
      next: "Toliau",
      back: "Atgal",
      submitRequest: "Pateikti užklausą",
      requestSent: "Užklausa sėkmingai išsiųsta!",
      okay: "Gerai",
      alertFillFields: "Prašome užpildyti visus privalomus laukus.",
      requestFailed: "Nepavyko išsiųsti užklausos",
      errorOccurred: "Įvyko klaida. Bandykite dar kartą.",
      generalCargo: "Bendrieji kroviniai",
      refrigeratedCargo: "Šaldomi kroviniai",
      hazardousCargo: "Pavojingi kroviniai",
      country_gb: "🇬🇧 Jungtinė Karalystė",
      country_fr: "🇫🇷 Prancūzija",
      country_be: "🇧🇪 Belgija",
      country_nl: "🇳🇱 Nyderlandai",
      country_de: "🇩🇪 Vokietija",
      country_it: "🇮🇹 Italija",
      country_lu: "🇱🇺 Liuksemburgas",
      country_es: "🇪🇸 Ispanija",
      country_lt: "🇱🇹 Lietuva",
      country_lv: "🇱🇻 Latvija",
      country_ee: "🇪🇪 Estija",
      country_fi: "🇫🇮 Suomija",
      country_se: "🇸🇪 Švedija",
      country_no: "🇳🇴 Norvegija",
      country_dk: "🇩🇰 Danija",
      country_ua: "🇺🇦 Ukraina",
      route: "Maršrutas",
      work_with: "Dirbama su",
      route_duration: "Maršruto trukmė",
      days: "dienos",
      salary: "Atlyginimas",
      euros_day: "eurų/diena",
      rest: "Poilsis",
      truck: "Sunkvežimis",
      rest_time_route: "Sutarta po kiekvieno maršruto",
      varies: "Kinta",
      trailer_car_carriers: "AUTOVEŽIAI, aukštos ir žemos kabinos, KASSBOHRER",
      job1: { title: "Tarptautiniai vairuotojai - Jungtinė Karalystė" },
      job2: { title: "Tarptautiniai vairuotojai - Suomija" },
      job3: { title: "Autovežių vairuotojas" },
      company_name: "GTV Logistics",
      company_code: "Įmonės kodas",
      vat_code: "PVM kodas",
      address: "Adresas",
      phone: "Telefonas",
      closeButton: "Gerai",
      contactSection: {
        contactTitle: "Kontaktai ir mūsų vieta",
        emailLabel: "El. pašto adresas:",
        phoneLabel: "Telefono numeris:",
        addressLabel: "Mus rasite:",
        contactFormTitle: "Kontaktų forma",
        nameLabel: "Jūsų vardas:",
        subjectLabel: "Pokalbio tema:",
        messageLabel: "Pranešimas",
        sendMessageButton: "Siųsti žinutę",
        copyEmailAriaLabel: "Kopijuoti el. paštą",
        copyPhoneAriaLabel: "Kopijuoti telefono numerį",
      },
      contactInfo: {
        sectionTitle: "Mūsų logistikos specialistai",
        karolina: "Karolina - Transporto logistikos vadybininkė",
        jurgita: "Jurgita - Transporto logistikos vadybininkė",
        egidijus: "Egidijus - Transporto logistikos vadybininkas",
        elvinas: "Elvinas - Transporto logistikos vadybininkas",
        customs: "Muitinės procedūros",
        accounting: "Buhalterija",
        copyPhoneAriaLabel: "Kopijuoti telefono numerį",
        copyEmailAriaLabel: "Kopijuoti el. paštą",
      },
      offerSectionModal: {
        title: "Pateikite užklausą",
        description: "Užpildykite visą informaciją, kad pateiktumėte užklausą.",
        fromLabel: "Iš kurios šalies",
        toLabel: "Į kurią šalį",
        phone: "El. paštas",
        selectPlaceholder: "Pasirinkite šalį",
        submitButton: "Pateikti",
        validationErrorTitle: "Formos klaida",
        validationErrorMessage: "Prašome užpildyti visus reikiamus laukus.",
        successTitle: "Užklausa pateikta",
        successMessage: "Jūsų užklausa sėkmingai pateikta!",
        errorTitle: "Užklausa nepavyko",
        errorMessage: "Įvyko klaida. Bandykite dar kartą.",
      },
      offerSection: {
        title: "Gaukite transportavimo pasiūlymą jau dabar",
        description:
          "Taip galėsite atrasti tinkamą kryptį, kuria mes transportuojame, ir kuri geriausiai atitinka jūsų poreikius.",
        emailLabel: "El. pašto adresas",
        emailLabel2: "El. pašto adresas:",
        fromLabel: "Pasirinkite iš kur:",
        toLabel: "Pasirinkite į kur:",
        selectPlaceholder: "Pasirinkite",
        checkboxLabel: "Sutinku gauti pasiūlymus ir reklamas el. paštu.",
        submitButton: "Tęsti užsakymą",
      },
      countries: {
        gb: { name: "Anglija", flag: "🇬🇧" },
        fr: { name: "Prancūzija", flag: "🇫🇷" },
        be: { name: "Belgija", flag: "🇧🇪" },
        nl: { name: "Olandija", flag: "🇳🇱" },
        de: { name: "Vokietija", flag: "🇩🇪" },
        it: { name: "Italija", flag: "🇮🇹" },
        lu: { name: "Liuksemburgas", flag: "🇱🇺" },
        es: { name: "Ispanija", flag: "🇪🇸" },
        lt: { name: "Lietuva", flag: "🇱🇹" },
        lv: { name: "Latvija", flag: "🇱🇻" },
        ee: { name: "Estija", flag: "🇪🇪" },
        fi: { name: "Suomija", flag: "🇫🇮" },
        se: { name: "Švedija", flag: "🇸🇪" },
        no: { name: "Norvegija", flag: "🇳🇴" },
        dk: { name: "Danija", flag: "🇩🇰" },
        ua: { name: "Ukraina", flag: "🇺🇦" },
      },
      footer: {
        workingHoursTitle: "Darbo laikas",
        weekdays: "I-IV: 8:00 - 17:00",
        friday: "V: 8:00 - 16:00",
        saturday: "Šeštadieniais: 10:00-15:00",
        sunday: "Sekmadieniais: tik telefonu",
        linksTitle: "Nuorodos",
        articlesTitle: "Straipsniai",
        logisticsArticle: "Logistika",
        servicesTitle: "Paslaugos",
        newsletterText: "Užsiprenumeruokite naujienlaiškį",
        emailPlaceholder: "Įveskite el. pašto adresą",
        copyrightText: "Autorinės teisės saugomos.",
        createdBy: "Sukurta:",
      },
    },
  },
  en: {
    translation: {
      servicesTitle: "Our Services",
      service: "car-transportation",
      service2: "freight-transport",
      service3: "customs-procedures",
      service4: "auction-purchase",
      //header
      orderButton: "Order now",
      headerTitle: "Your trusted logistics partner",
      headerDescription:
        "We are a leading company in used vehicle transportation from the UK to Lithuania, Latvia, Estonia, the Netherlands, and Ukraine.",
      transportInquiry: "Order request",
      getButton: "Get an offer",
      readMore: "Read More",
      chooseLanguage: "Choose language",
      serviceReadMore: "/services/car-transportation/",
      serviceReadMore2: "/services/freight-transport/",
      serviceReadMore3: "/services/customs-procedures/",
      serviceReadMore4: "/services/auction-purchase/",
      selectService: "Select a service",
      vehicleServices: "Vehicle Services",
      cargoTransportation: "Cargo Transportation",
      customsProcedures: "Customs Procedures",
      auctionPurchases: "Auction Purchases",
      fromCountry: "Select from country",
      toCountry: "Select to country",
      carMakeModel: "Car Make and Model",
      pickUpAddress: "Pick-up Address",
      dropOffAddress: "Drop-off Address",
      cargoType: "Select Cargo Type",
      weight: "Weight (kg)",
      dimensions: "Dimensions (length x width x height)",
      loadingAddress: "Loading Address",
      unloadingAddress: "Unloading Address",
      customsDetails: "Please specify the required customs procedures",
      auctionLink: "Insert auction link",
      yourInformation: "Your Information",
      name: "Name",
      email: "Email",
      phone: "Phone Number",
      next: "Next",
      back: "Back",
      submitRequest: "Submit Request",
      requestSent: "Request successfully sent!",
      okay: "Okay",
      alertFillFields: "Please fill in all required fields.",
      requestFailed: "Failed to send request",
      errorOccurred: "An error occurred. Please try again.",
      generalCargo: "General Cargo",
      refrigeratedCargo: "Refrigerated Cargo",
      hazardousCargo: "Hazardous Cargo",
      country_gb: "🇬🇧 United Kingdom",
      country_fr: "🇫🇷 France",
      country_be: "🇧🇪 Belgium",
      country_nl: "🇳🇱 Netherlands",
      country_de: "🇩🇪 Germany",
      country_it: "🇮🇹 Italy",
      country_lu: "🇱🇺 Luxembourg",
      country_es: "🇪🇸 Spain",
      country_lt: "🇱🇹 Lithuania",
      country_lv: "🇱🇻 Latvia",
      country_ee: "🇪🇪 Estonia",
      country_fi: "🇫🇮 Finland",
      country_se: "🇸🇪 Sweden",
      country_no: "🇳🇴 Norway",
      country_dk: "🇩🇰 Denmark",
      country_ua: "🇺🇦 Ukraine",
      closeButton: "Okey",
      route: "Route",
      work_with: "Work with",
      route_duration: "Route duration",
      days: "days",
      salary: "Salary",
      euros_day: "euros/day",
      rest: "Rest",
      truck: "Truck",
      rest_time_route: "Agreed after each route",
      varies: "Varies",
      trailer_car_carriers: "CAR CARRIERS, high and low cabins, KASSBOHRER",
      job1: { title: "International Drivers - United Kingdom" },
      job2: { title: "International Drivers - Finland" },
      job3: { title: "Car Carrier Driver" },
      company_name: "GTV Logistics",
      company_code: "Company code",
      vat_code: "VAT code",
      address: "Address",
      phone: "Phone",
      contactSection: {
        contactTitle: "Contacts and our location",
        emailLabel: "Email address:",
        phoneLabel: "Phone number:",
        addressLabel: "Find us at:",
        contactFormTitle: "Contact form",
        nameLabel: "Your name:",
        subjectLabel: "Subject:",
        messageLabel: "Message",
        sendMessageButton: "Send message",
        copyEmailAriaLabel: "Copy email",
        copyPhoneAriaLabel: "Copy phone number",
      },
      contactInfo: {
        sectionTitle: "Our Logistics Specialists",
        karolina: "Karolina - Transport Logistics Manager",
        jurgita: "Jurgita - Transport Logistics Manager",
        egidijus: "Egidijus - Transport Logistics Manager",
        elvinas: "Elvinas - Transport Logistics Manager",
        customs: "Customs Procedures",
        accounting: "Accounting",
        copyPhoneAriaLabel: "Copy phone number",
        copyEmailAriaLabel: "Copy email",
      },
      offerSection: {
        title: "Get a transportation offer now",
        description:
          "This way you can find the right route for transportation that best fits your needs.",
        emailLabel: "Email address",
        emailLabel2: "Email address:",
        fromLabel: "Select from:",
        toLabel: "Select to:",
        selectPlaceholder: "Select",
        checkboxLabel: "I agree to receive offers and promotions via email.",
        submitButton: "Continue order",
      },
      countries: {
        gb: { name: "United Kingdom", flag: "🇬🇧" },
        fr: { name: "France", flag: "🇫🇷" },
        be: { name: "Belgium", flag: "🇧🇪" },
        nl: { name: "Netherlands", flag: "🇳🇱" },
        de: { name: "Germany", flag: "🇩🇪" },
        it: { name: "Italy", flag: "🇮🇹" },
        lu: { name: "Luxembourg", flag: "🇱🇺" },
        es: { name: "Spain", flag: "🇪🇸" },
        lt: { name: "Lithuania", flag: "🇱🇹" },
        lv: { name: "Latvia", flag: "🇱🇻" },
        ee: { name: "Estonia", flag: "🇪🇪" },
        fi: { name: "Finland", flag: "🇫🇮" },
        se: { name: "Sweden", flag: "🇸🇪" },
        no: { name: "Norway", flag: "🇳🇴" },
        dk: { name: "Denmark", flag: "🇩🇰" },
        ua: { name: "Ukraine", flag: "🇺🇦" },
      },
      footer: {
        workingHoursTitle: "Working Hours",
        weekdays: "Mon-Thu: 8:00 AM - 5:00 PM",
        friday: "Fri: 8:00 AM - 4:00 PM",
        saturday: "Saturdays: 10:00 AM - 3:00 PM",
        sunday: "Sundays: by phone only",
        linksTitle: "Links",
        articlesTitle: "Articles",
        logisticsArticle: "Logistics",
        servicesTitle: "Services",
        newsletterText: "Subscribe to our newsletter",
        emailPlaceholder: "Enter your email address",
        copyrightText: "All rights reserved.",
        createdBy: "Created by:",
      },
    },
  },
  ru: {
    translation: {
      servicesTitle: "Наши услуги",
      service: "perevozka-avtomobiley",
      service2: "perevozka-gruzov",
      service3: "tamozhennyye-protsedury",
      service4: "auktsionnyye-pokupki",
      //header
      orderButton: "Заказать сейчас",
      headerTitle: "Ваш надежный партнер в логистике",
      headerDescription:
        "Мы являемся ведущей компанией по транспортировке подержанных автомобилей из Великобритании в Литву, Латвию, Эстонию, Нидерланды и Украину.",
      transportInquiry: "ЗАПРОС НА ТРАНСПОРТИРОВКУ",
      getButton: "Получить предложение",
      readMore: "Читать далее",
      chooseLanguage: "Выберите язык",
      serviceReadMore: "/uslugi/perevozka-avtomobiley/",
      serviceReadMore2: "/uslugi/perevozka-gruzov/",
      serviceReadMore3: "/uslugi/tamozhennyye-protsedury/",
      serviceReadMore4: "/uslugi/auktsionnyye-pokupki/",
      selectService: "Выберите услугу",
      vehicleServices: "Услуги по транспортировке автомобилей",
      cargoTransportation: "Грузоперевозки",
      customsProcedures: "Таможенные процедуры",
      auctionPurchases: "Покупки с аукционов",
      fromCountry: "Выберите страну отправления",
      toCountry: "Выберите страну назначения",
      carMakeModel: "Марка и модель автомобиля",
      pickUpAddress: "Адрес для получения",
      dropOffAddress: "Адрес для доставки",
      cargoType: "Выберите тип груза",
      weight: "Вес (кг)",
      dimensions: "Габариты (длина x ширина x высота)",
      loadingAddress: "Адрес погрузки",
      unloadingAddress: "Адрес разгрузки",
      customsDetails: "Пожалуйста, укажите необходимые таможенные процедуры",
      auctionLink: "Вставьте ссылку на аукцион",
      yourInformation: "Ваша информация",
      name: "Имя",
      email: "Электронная почта",
      phone: "Номер телефона",
      next: "Далее",
      back: "Назад",
      submitRequest: "Отправить запрос",
      requestSent: "Запрос успешно отправлен!",
      okay: "Окей",
      alertFillFields: "Пожалуйста, заполните все обязательные поля.",
      requestFailed: "Не удалось отправить запрос",
      errorOccurred: "Произошла ошибка. Пожалуйста, попробуйте еще раз.",
      generalCargo: "Общий груз",
      refrigeratedCargo: "Холодильный груз",
      hazardousCargo: "Опасный груз",
      country_gb: "🇬🇧 Соединённое Королевство",
      country_fr: "🇫🇷 Франция",
      country_be: "🇧🇪 Бельгия",
      country_nl: "🇳🇱 Нидерланды",
      country_de: "🇩🇪 Германия",
      country_it: "🇮🇹 Италия",
      country_lu: "🇱🇺 Люксембург",
      country_es: "🇪🇸 Испания",
      country_lt: "🇱🇹 Литва",
      country_lv: "🇱🇻 Латвия",
      country_ee: "🇪🇪 Эстония",
      country_fi: "🇫🇮 Финляндия",
      country_se: "🇸🇪 Швеция",
      country_no: "🇳🇴 Норвегия",
      country_dk: "🇩🇰 Дания",
      country_ua: "🇺🇦 Украина",
      closeButton: "Хорошо",
      route: "Маршрут",
      work_with: "Работа с",
      route_duration: "Продолжительность маршрута",
      days: "дней",
      salary: "Зарплата",
      euros_day: "евро/день",
      rest: "Отдых",
      truck: "Грузовик",
      rest_time_route: "Согласовано после каждого маршрута",
      varies: "Варьируется",
      trailer_car_carriers: "АВТОВОЗЫ, высокие и низкие кабины, KASSBOHRER",
      job1: { title: "Международные водители - Великобритания" },
      job2: { title: "Международные водители - Финляндия" },
      job3: { title: "Водитель автовоза" },
      company_name: "GTV Logistics",
      company_code: "Код компании",
      vat_code: "Код НДС",
      address: "Адрес",
      phone: "Телефон",
      contactSection: {
        contactTitle: "Контакты и наше местоположение",
        emailLabel: "Электронная почта:",
        phoneLabel: "Номер телефона:",
        addressLabel: "Нас можно найти по адресу:",
        contactFormTitle: "Форма для связи",
        nameLabel: "Ваше имя:",
        subjectLabel: "Тема разговора:",
        messageLabel: "Сообщение",
        sendMessageButton: "Отправить сообщение",
        copyEmailAriaLabel: "Скопировать email",
        copyPhoneAriaLabel: "Скопировать номер телефона",
      },
      contactInfo: {
        sectionTitle: "Наши специалисты по логистике",
        karolina: "Каролина - Менеджер по транспортной логистике",
        jurgita: "Юргита - Менеджер по транспортной логистике",
        egidijus: "Эгидиус - Менеджер по транспортной логистике",
        elvinas: "Элвинас - Менеджер по транспортной логистике",
        customs: "Таможенные процедуры",
        accounting: "Бухгалтерия",
        copyPhoneAriaLabel: "Скопировать номер телефона",
        copyEmailAriaLabel: "Скопировать email",
      },

      offerSectionModal: {
        title: "Submit your request",
        description: "Please fill in the details to submit your request.",
        fromLabel: "From Country",
        toLabel: "To Country",
        phone: "Email",
        selectPlaceholder: "Select a country",
        submitButton: "Submit",
        validationErrorTitle: "Form Error",
        validationErrorMessage: "Please fill in all the required fields.",
        successTitle: "Request Sent",
        successMessage: "Your request was successfully submitted!",
        errorTitle: "Request Failed",
        errorMessage: "Something went wrong. Please try again.",
      },

      offerSection: {
        title: "Получите предложение по транспортировке прямо сейчас",
        description:
          "Таким образом, вы можете выбрать подходящий маршрут, который наилучшим образом соответствует вашим потребностям.",
        emailLabel: "Электронная почта",
        emailLabel2: "Электронная почта:",
        fromLabel: "Выберите откуда:",
        toLabel: "Выберите куда:",
        selectPlaceholder: "Выбрать",
        checkboxLabel:
          "Я согласен получать предложения и рекламные материалы по электронной почте.",
        submitButton: "Продолжить заказ",
        countries: {
          gb: { name: "Великобритания", flag: "🇬🇧" },
          fr: { name: "Франция", flag: "🇫🇷" },
          be: { name: "Бельгия", flag: "🇧🇪" },
          nl: { name: "Нидерланды", flag: "🇳🇱" },
          de: { name: "Германия", flag: "🇩🇪" },
          it: { name: "Италия", flag: "🇮🇹" },
          lu: { name: "Люксембург", flag: "🇱🇺" },
          es: { name: "Испания", flag: "🇪🇸" },
          lt: { name: "Литва", flag: "🇱🇹" },
          lv: { name: "Латвия", flag: "🇱🇻" },
          ee: { name: "Эстония", flag: "🇪🇪" },
          fi: { name: "Финляндия", flag: "🇫🇮" },
          se: { name: "Швеция", flag: "🇸🇪" },
          no: { name: "Норвегия", flag: "🇳🇴" },
          dk: { name: "Дания", flag: "🇩🇰" },
          ua: { name: "Украина", flag: "🇺🇦" },
        },
      },
      footer: {
        workingHoursTitle: "Часы работы",
        weekdays: "Пн-Чт: 8:00 - 17:00",
        friday: "Пт: 8:00 - 16:00",
        saturday: "Суббота: 10:00 - 15:00",
        sunday: "Воскресенье: только по телефону",
        linksTitle: "Ссылки",
        articlesTitle: "Статьи",
        logisticsArticle: "Логистика",
        servicesTitle: "Услуги",
        newsletterText: "Подпишитесь на нашу рассылку",
        emailPlaceholder: "Введите свой email",
        copyrightText: "Все права защищены.",
        createdBy: "Создано:",
      },
    },
  },
}

// Funkcija nustatyti pradinę kalbą tik kliento pusėje
const initialLanguage = () => {
  if (typeof window !== "undefined" && window.localStorage) {
    const savedLanguage = localStorage.getItem("language")
    return savedLanguage || "lt" // Jei išsaugota kalba nėra, naudoja "lt"
  }
  return "lt" // Naudojama pradinė kalba "lt" serverio pusėje
}

i18n.use(initReactI18next).init({
  resources,
  lng: initialLanguage(), // Naudojame pradinę kalbą iš localStorage arba numatytąją "lt"
  fallbackLng: "lt",
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
